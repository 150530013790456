//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CommonMixin from "@/core/plugins/common-mixin";
export default {
  mixins: [CommonMixin],
  props: {
    dataLoading: {
      type: Boolean
    },
    allowedSearchFields: {
      type: Array,
      required: true,
      default: function _default() {
        return new Array();
      }
    }
  },
  methods: {
    clearSearch: function clearSearch() {
      this.$emit("clear-search", true);
    },
    advancedSearch: function advancedSearch() {
      this.$emit("advanced-search", true);
    }
  },
  computed: {
    searchObject: function searchObject() {
      var result = new Array();

      for (var i = 0; i < this.allowedSearchFields.length; i++) {
        if (this.$route.query[this.allowedSearchFields[i].key]) {
          if (this.lodash.isArray(this.$route.query[this.allowedSearchFields[i].key])) {
            if (this.lodash.isEmpty(this.$route.query[this.allowedSearchFields[i].key]) === false) {
              result.push(this.allowedSearchFields[i]);
            }
          } else {
            result.push(this.allowedSearchFields[i]);
          }
        }
      }

      return result;
    }
  }
};